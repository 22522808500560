<template>
    <div>
      <v-snackbar v-model="snackbar" top :timeout="5000" :color="color">
        {{text}}
        <v-spacer></v-spacer>
        <v-btn icon @click="snackbar = false"><v-icon color="white">mdi-close</v-icon></v-btn>
      </v-snackbar>
      <v-overlay :value="true" :z-index="5">
          <v-card light class="pa-5 ma-1 text-center" min-width="350px">
            <v-card-text>
              <v-icon x-large color="blueDark" class="mb-5">mdi-reload</v-icon>
              <p class="body-1 font-weight-bold primary--text">Your search has expired, what do you want to do ?</p>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
              <v-row justify="space-around">
                <v-col>
                  <v-btn text color="warning" @click="goHome">Cancel</v-btn>
                </v-col>
                <v-col>
                  <v-btn tile color="secondary" @click="refresh" :loading="loading">{{$route.name !== 'book' ? 'Refresh results' : 'Back To results'}}</v-btn>
                </v-col>
                <v-col v-if="$route.name === 'book'">
                  <v-btn tile color="secondary" @click="recheck" :loading="checkLoading">Recheck availability</v-btn>
                </v-col>
              </v-row>
            </v-card-actions>
          </v-card>
      </v-overlay>
    </div>
</template>

<script>
import { checkFlight, headersNoAuth } from '../links'
export default {
  props: ['flight'],
  data () {
    return {
      loading: false,
      checkLoading: false,
      snackbar: false,
      color: '',
      text: ''
    }
  },
  methods: {
    refresh () {
      if (this.$route.name === 'book' || this.$route.name === 'flightResults') {
        this.request(localStorage.getItem('alternateSearchTerm'))
      } else if (this.$route.name === 'oneHotel' || this.$route.name === 'hotelsResults' || this.$route.name === 'hotelBook') {
        this.request(localStorage.getItem('hotelsTerm'))
      } else {
        const carSearch = this.$store.state.carInfo
        let term = `pickUpDate=${carSearch.pickup}&pickUpTime=${carSearch.pickupTime}&returnDate=${carSearch.drop}&returnTime=${carSearch.dropTime}&pickUpLocation=${carSearch.pickupLocation.Code}&sortOrder=ASC`
        if (carSearch.dropLocation) term += `&dropOffLocation=${carSearch.dropLocation.Code}`
        this.request(term)
      }
    },
    request (data) {
      document.body.style.height = '100%'
      this.loading = true
      if (this.$route.name === 'book' || this.$route.name === 'flightResults') {
        this.$store.dispatch('removeFlightResults')
        this.$emit('newSearch', data)
        if (this.$route.name !== 'flightResults') this.$router.push({ name: 'flightResults' })
      } else if (this.$route.name === 'oneHotel' || this.$route.name === 'hotelsResults' || this.$route.name === 'hotelBook') {
        this.$store.dispatch('removeHotelsResults')
        this.$emit('newSearch', data)
        if (this.$route.name !== 'hotelsResults') this.$router.push({ name: 'hotelsResults' })
      } else {
        this.$store.dispatch('removeCarsResults')
        this.$emit('newSearch', data)
        if (this.$route.name !== 'carsResults') this.$router.push({ name: 'carsResults' })
      }
    },
    recheck () {
      if (this.flight) {
        this.checkLoading = true
        this.$http.post(checkFlight, this.flight, { headers: headersNoAuth() }).then(response => {
          this.checkLoading = false
          if (response.body.status === true) {
            this.$emit('flightAvailable', response.body.data)
            document.body.style.height = '100%'
          } else {
            this.$emit('flightNotAvailable')
          }
        }, () => {
          this.checkLoading = false
          this.$emit('flightNotAvailable')
        })
      }
    },
    goHome () {
      document.body.style.height = '100%'
      if (this.$route.name === 'book' || this.$route.name === 'flightResults') {
        if (this.$store.state.flightsTimeOutFunction) this.$store.dispatch('clearFlightsTimeoutFunction')
        this.$router.push({ name: 'flights' })
      } else if (this.$route.name === 'carBook' || this.$route.name === 'carsResults') {
        if (this.$store.state.carsTimeOutFunction) this.$store.dispatch('clearCarsTimeOutFunction')
        this.$router.push({ name: 'cars' })
      } else {
        if (this.$store.state.hotelTimeOutFunction) this.$store.dispatch('clearHotelTimeoutFunction')
        this.$router.push({ name: 'hotels' })
      }
    }
  },
  created () {
    document.body.style.height = '100vh'
    document.body.style.overflow = 'hidden'
  }
}
</script>
